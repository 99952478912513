
import * as React from 'react';
import * as maplibregl from "maplibre-gl";

const LoginScreen = ({server, setServer, setUser}) => {
  console.log("should show login server")
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const fetchData = async () => {
      if (server.newServer) {
        const response = await fetch('https://locate.etvhosting.com/api/users', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({name: email, email, password}),
        });
        if (response.ok) {
          setServer({...server, newServer: false});
        }
      } else {
        const query = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
        const response = await fetch('https://locate.etvhosting.com/api/session', {
          method: 'POST',
          body: new URLSearchParams(query),
        });
        if (response.ok) {
          setUser(await response.json());
        }
      }
    }
    fetchData();
  };

  const formStyle = {
    width: '320px',
    margin: '32px',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
      <form onSubmit={handleSubmit} style={formStyle}>
        <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
        />
        <input
            password={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
        />
        <button type="submit">
          {server.newServer ? 'Register' : 'Login'}
        </button>
      </form>
  );
};

function MainScreen ({setUser}) {
  console.log("should show main screen")
  const mapContainer = React.useRef();
  const map = React.useRef();

  React.useEffect(() => {
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://demotiles.maplibre.org/style.json',
      center: [0, 0],
      zoom: 1,
    });
  }, []);

  const [devices, setDevices] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const devicesResponse = await fetch('https://locate.etvhosting.com/api/devices');
      if (devicesResponse.ok) {
        setDevices(await devicesResponse.json());
      }
    }
    fetchData();
  }, []);

  const [initialized, setInitialized] = React.useState(false);
  const [positions, setPositions] = React.useState({});

  React.useEffect(() => {
    if (initialized) {
      const url = window.location.protocol + '//' + window.location.host;
      console.log('wss' + url.substring(4) + '//locate.etvhosting.com/api/socket');
      const socket = new WebSocket('ws' + url.substring(4) + '//locate.etvhosting.com/api/socket');
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const updatedPositions = {};
        data.positions?.forEach((p) => updatedPositions[p.deviceId] = p);
        setPositions({...positions, ...updatedPositions})
      };
    }
  }, [initialized]);

  const handleAddDevice = (event) => {
    event.preventDefault();
    const fetchData = async () => {
      const id = prompt('Enter device id');
      const response = await fetch('https://locate.etvhosting.com/api/devices', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          name: id,
          uniqueId: id,
        }),
      });
      if (response.ok) {
        setDevices([...devices, await response.json()]);
      }
    }
    fetchData();
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const fetchData = async () => {
      await fetch('https://locate.etvhosting.com/api/session', {method: 'DELETE'});
      setUser(null);
    }
    fetchData();
  };

  React.useEffect(() => {
    map.current.on('load', () => {
      map.current.addSource('points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      map.current.addLayer({
        id: 'points',
        type: 'circle',
        source: 'points',
      });
      setInitialized(true);
    });
  }, []);

  React.useEffect(() => {
    map.current.getSource('points')?.setData({
      type: 'FeatureCollection',
      features: Object.values(positions).map((position) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
      })),
    });
  }, [positions]);

  const containerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
  };
  const deviceStyle = {
    width: '320px',
    marginTop: '16px',
  };
  const mapStyle = {
    height: '100%',
    flexGrow: 2,
  };

  return (
      <div style={containerStyle}>
        <div style={mapStyle} ref={mapContainer}></div>
      </div>
  );
}

const App = () => {
  const [server, setServer] = React.useState();
  const [user, setUser] = React.useState();

  console.log(user)
  React.useEffect(() => {

    const fetchData = async () => {
      const serverResponse = await fetch('https://locate.etvhosting.com/api/server');
      if (serverResponse.ok) {
        setServer(await serverResponse.json());
      }
      const sessionResponse = await fetch('https://locate.etvhosting.com/api/session/?token=RzBFAiBVF8NnjmugozzwNFOp3Ban3C4FoSIDzVawz0xCF7BZ8QIhAK6lITp28ymqR-Jd4GYQXqpjYHwWSWa7cqq3DZlK_b_beyJ1Ijo0LCJlIjoiMjAyNC0xMi0xMFQwNjowMDowMC4wMDArMDA6MDAifQ');
      if (sessionResponse.ok) {
        setUser(await sessionResponse.json());
      }
    }
    
  
   
    fetchData();
  }, []);

  return user ? (

      <MainScreen
          setUser={setUser}
      />
  ) : server ? (
      <LoginScreen
          server={server}
          setServer={setServer}
          setUser={setUser}
      />
  ) : '';
};

export default App;
